<template>
  <b-sidebar
    id="add-new-sidebar"
    :visible="isAddNewSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => toggleSidebar(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titleForm }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-form-group
          label="Depende de?"
          label-for="moduloPadre"
        >
          <v-select
            v-model="formData.moduloPadre"
            placeholder=".: Seleccione Módulo Padre :."
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="modulesParent"
            :reduce="m => m.idModulo"
            label="nombre"
            :clearable="false"
            input-id="moduloPadre"
          />
        </b-form-group>
        <b-form-group
          label="Nombre Módulo"
          label-for="nombre_modulo"
        >
          <b-form-input
            id="nombre_modulo"
            v-model="formData.nombre"
            autofocus
            trim
            placeholder=""
          />
        </b-form-group>
        <b-form-group
          label="Route"
          label-for="url"
        >
          <b-form-input
            id="url"
            v-model="formData.url"
            trim
          />
        </b-form-group>
        <b-form-group
          label="Icono"
          label-for="icono_modulo"
        >
          <b-form-input
            id="icono_modulo"
            v-model="formData.icono"
            trim
          />
        </b-form-group>
        <b-form-group
          label="Orden"
          label-for="nroOrden"
        >
          <b-form-input
            id="nroOrden"
            v-model="formData.nroOrden"
            trim
          />
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="button"
            @click="saveForm"
          >
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancelar
          </b-button>
        </div>

      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BButton } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewSidebarActive',
    event: 'update:is-add-new-sidebar-active',
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    dataEdit: {
      type: Object,
      required: false,
      default: null,
    },
    formType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const titleForm = ref('')
    const modulesParent = ref([])
    const moduleParentSel = ref({})

    const formData = ref({})
    const resetForm = () => {
      moduleParentSel.value = {}
      formData.value = {}
    }

    const saveForm = () => {
      let service = 'seguridad/MODULO_CREATE'
      if (props.formType === 'edit') {
        service = 'seguridad/MODULO_UPDATE'
      }
      // formData.value.sistema = props.dataSistema
      store.dispatch(service, formData.value)
        .then(response => {
          emit('refetch-data', response)
          emit('update:is-add-new-sidebar-active', false)
        })
        .catch(error => {
          emit('error-data', error)
          emit('update:is-add-new-sidebar-active', false)
        })
    }

    const loadParents = () => {
      const moduleDefault = ref({
        idModulo: 0,
        nombre: 'Ninguno',
      })
      modulesParent.value.push(moduleDefault.value)
      store
        .dispatch('seguridad/MODULO_FIND_ALL', {
          tipo: 'lista',
          idModuloPadre: 0,
          idPerfil: -1,
          limit: -1,
          query: '',
          page: -1,
          sortBy: 'nroOrden%7CASC',
        })
        .then(response => {
          if (response !== undefined) {
            modulesParent.value = response
            modulesParent.value.unshift(moduleDefault.value)
          }
        })
        .catch(error => {
          emit('error-data', error)
        })
    }

    const toggleSidebar = val => {
      resetForm()
      loadParents()
      emit('update:is-add-new-sidebar-active', val)
      if (props.formType === 'new') {
        titleForm.value = 'Agregar Módulo'
      }
      if (props.formType === 'edit') {
        formData.value = {
          idModulo: props.dataEdit.idModulo,
          nombre: props.dataEdit.nombre,
          url: props.dataEdit.url,
          icono: props.dataEdit.icono,
          moduloPadre: props.dataEdit.moduloPadre,
          nroOrden: props.dataEdit.nroOrden,
          estado: props.dataEdit.estado,
        }
        titleForm.value = 'Editar Módulo'
      }
    }

    return {
      formData,
      saveForm,
      resetForm,
      loadParents,
      modulesParent,
      toggleSidebar,
      titleForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
